import CookieService from "@/services/CookieService";
import CommonService, {isEmpty, isNotEmpty} from "@/services/CommonService";
import CommonElem from "@/services/CommonElem";
import CryptoJS from "crypto-js";
import cryptoRandomString from "crypto-random-string";
import basex from "base-x";
import crypto from "crypto";

export const FAST_LOGIN_QUERY = "fastLoginKey"
export const ADMIN_QUERY = "admin"

const base62 = basex("ABCDEFGHIJKLMNOPQRSTUVWXYZ")

function _shortHash(input) {
    return
}

export async function fastLogin(fastLoginKey, admin) {
    const _fastLoginKey = fastLoginKey.toUpperCase().replace(" ", "")
    const dto = {
        fastLoginKey: CryptoJS.MD5(`${_fastLoginKey}`).toString(),
        admin,
    }

    const res = await CommonService.post(`/public/fastLogin/login`, dto)

    if(res.error) {
        CommonElem.error("Jednorázové přihlášení se nezdařilo.")
        return false
    }

    console.log(`Úspěšně přihlášený`);

    const tokenDto = res.tokenDto
    CookieService.setCookie(CookieService.NAMES.TOKEN, tokenDto.token, Infinity)
    CookieService.setCookie(CookieService.NAMES.CONG_NAME, tokenDto.congName, Infinity)
    CookieService.setCookie(CookieService.NAMES.USER, tokenDto.user, Infinity);

    const keyObject = res.encryptedKeyObject
    console.log(`Úspěšně přihlášený. Klíč?`, isNotEmpty(keyObject));
    if (isNotEmpty(keyObject)) {
        const decryptedKeyObject = _decryptWithKey(fastLoginKey, keyObject)
        console.log(`[FastLoginCtrl.loginFromPwa] decrypted key`, decryptedKeyObject)

        return decryptedKeyObject
    }

    return {}
}

function _getFastLoginKey(keyObject) {
    if (isEmpty(keyObject))
        return cryptoRandomString({length: 9, characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'}).replace(" ", "")

    const hash = crypto.createHash("sha256").update(keyObject.userKey).digest()
    return base62.encode(hash).slice(0, 9).toUpperCase()
}

/**
 * @param keyObject Objekt pouze s jedním atributem a to: .userKey nebo .congKey.
 */
export async function prepareFastLogin(user, keyObject, prolongHours) {
    const fastLoginKey = _getFastLoginKey(keyObject)
    // console.log(`[FastLoginCtrl.prepareFastLogin] fastLoginKey:`, fastLoginKey)
    // console.log(`[FastLoginCtrl.prepareFastLogin] keyObject:`, keyObject)

    const dto = {
        userId: user.id,
        fastLoginKey: CryptoJS.MD5(`${fastLoginKey}`).toString(),
        encryptedKeyObject: _encryptWithKey(fastLoginKey, keyObject),
        prolongHours,
    }

    const res = await CommonService.post("/private/fastLogin/prepare", dto)
    if(res.error) return false

    return fastLoginKey
}

function _encryptWithKey(key, object) {
    const objectInString = JSON.stringify(object);
    const encrypted = CryptoJS.AES.encrypt(objectInString, `${key}`).toString()
    return encrypted
}

function _decryptWithKey(key, encrypedObject) {
    const bytes  = CryptoJS.AES.decrypt(encrypedObject, `${key}`)
    const decryptedObject = bytes.toString(CryptoJS.enc.Utf8)
    const object = JSON.parse(decryptedObject)
    // console.log(`[FastLoginCtrl._decryptWithFingerprint] decrypted:`, object)
    return object
}