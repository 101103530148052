import router from "@/router";
import axios from 'axios';
import properties from "@/utils/properties";
import CookieService from "@/services/CookieService";
import CommonElem from "@/services/CommonElem";
import msg from "@/utils/msg";

export function processError(error, errorCallback) {

    //console.log("[CommonService.processError] error: ", error);
    //console.log(error.response.status);

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        const dto = error.response.data
        CommonElem.state.loading = false

        console.error("[CommonService.processError] not 2xx", dto);

        if (errorCallback) {
            if (errorCallback(dto))
                return null
        }


        if (!isEmpty(dto.message)) {
            CommonElem.msg.error(dto.message)
            return null
        } else if (isEmpty(dto.code)) {
            CommonElem.msg.error("Neznámá chyba.")
            return null
        }

        let text = ""

        if (dto.code.endsWith("empty")) {
            const msgElement = msg.get(dto.code.replace('.empty',''))

            console.log(dto.code.replace('.empty',''))

            if (isEmpty(msgElement)) {
                text = `Zadané pole ${msgElement.name} nesmí být prázdné.`
            } else if (msgElement.rod === "m") {
                text = `Zadaný ${msgElement.name} nesmí být prázdný.`
            } else if (msgElement.rod === "f") {
                text = `Zadaná ${msgElement.name} nesmí být prázdná.`
            } else if (msgElement.rod === "it") {
                text = `Zadané ${msgElement.name} nesmí být prázdné.`
            }
        } else if (dto.code.endsWith("invalid")) {
            const msgElement = msg.get(dto.code.replace('.invalid',''))

            console.log("msgElement")
            console.log(dto.code.replace('.invalid',''))

            if (msgElement.rod === "m") {
                text = `Zadaný ${msgElement.name} není validní.`
            } else if (msgElement.rod === "f") {
                text = `Zadaná ${msgElement.name} není validní.`
            } else if (msgElement.rod === "it") {
                text = `Zadané ${msgElement.name} není validní.`
            }
        } else {
            text = msg.get(dto.code)
        }

        CommonElem.msg.error(text)

        return null
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        CommonElem.msg.error("[CommonService.processError] Nepovedlo se kontaktovat server.")
        CommonElem.state.loading = false
        CommonElem.dark.hide()
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('[CommonService.processError] Unusual error', error);
    }
}

export function isEmpty(obj) {
    return obj === undefined || !obj || Object.keys(obj).length === 0 || obj === ""
}

export function redirect(url) {
    console.log("[CommonService.redirect] to ", url)
    router.push({name: url});
}

export function headers() {
    const token = CookieService.getCookie(CookieService.NAMES.TOKEN);
    return { headers: {
        'Content-Type': 'application/json',
        'Authorization' : token,
    }};
}

export function post(url, data, errorCallback) {
    // console.log("[REST POST] " + properties.apiUrl + url);

    let reqFailed = false

    return axios.post(properties.apiUrl + url, data, this.headers())
        .catch(function (error) {
            reqFailed = true
            return processError(error, errorCallback)
        }).then(res => {
            // console.log("common get");
            if (res && res.data && res.data.data) {
                if (reqFailed) {
                    res.data.data.error = reqFailed
                }
                return res.data.data
            }

            return {error: reqFailed}
        })
}

export function get(url, customError) {
    // console.log(`[REST GET] ${properties.apiUrl}${url}`);

    let reqFailed = false

    return axios.get(properties.apiUrl + url, this.headers()).catch(function (error) {
        reqFailed = true
        return processError(error, customError)
    }).then(res => {
        // console.log("common get");
        // console.log(res.data.data);
        if (res && res.data && res.data.data) {
            if (reqFailed) {
                res.data.data.error = reqFailed
            }
            return res.data.data
        }

        return {error: reqFailed}
    })
}

export function clone (source) {
    if (!source) {
        return null;
    }
    return JSON.parse(JSON.stringify(source));
}

export function copyProps(from, to) {
    Object.keys(from)
        .forEach(fromKey => to[fromKey] = from[fromKey])

    return to
}

export function dataToExcel(file, resultName) {
    // Decode base64 string to byte array
    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });

    // Use file-saver to save the Blob as a file and trigger the download
    const url = URL.createObjectURL(blob);

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = resultName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function normalize(inputText) {
    let r = inputText.toLowerCase();
    r = r.replace(new RegExp(/[áä]/g), "a")
    r = r.replace(new RegExp(/[č]/g), "c")
    r = r.replace(new RegExp(/[ď]/g), "d")
    r = r.replace(new RegExp(/[éěë]/g), "e")
    r = r.replace(new RegExp(/[íï]/g), "i")
    r = r.replace(new RegExp(/[óö]/g), "o")
    r = r.replace(new RegExp(/[ř]/g), "r")
    r = r.replace(new RegExp(/[š]/g), "s")
    r = r.replace(new RegExp(/[ť]/g), "t")
    r = r.replace(new RegExp(/[úůü]/g), "u")
    r = r.replace(new RegExp(/[ýÿ]/g), "y")
    r = r.replace(new RegExp(/[ž]/g), "z")
    r = r.replace(new RegExp(/[ň]/g), "n")

    return r
}

/**
 * @param s1 searching in this text
 * @param s2 searching for this
 */
export function matchTexts(testRule, s1, s2) {
    if (!testRule || !s1) {
        return true;
    }

    const testNormalized = new RegExp(this.normalize(testRule).toLowerCase())
    const s1Normal = this.normalize(s1).toLowerCase()
    const s2Normal = isNotEmpty(s2) ? this.normalize(s2).toLowerCase() : null

    //console.log(`s1normal: ${s1normal}, s2Normal: ${s2Normal}, equals: ${s2Normal.test(s1normal)}`);

    if (testNormalized.test(s1Normal))
        return true
    else if (s2Normal)
        return testNormalized.test(s2Normal)
    else
        return false
}

export function equalsNormalize (s1, s2) {
    return this.normalize(s1) === this.normalize(s2)
}

export function removeFromArray(array, itemToRemove) {
    const index = array.indexOf(itemToRemove)
    if (-1 < index) {
        array.splice(index, 1)
        return true
    } else {
        return false
        /*console.error("Item (1) not found in array (2):")
        console.error(itemToRemove)
        console.error(array)*/
    }
}

export function waitFor(when, then) {
    let interval = setInterval(function() {
        if (when()) {
            then()
            clearInterval(interval);
        }
    }, 100); // check every 100ms
}

export function tryMultiple(action, timeout, times) {
    if (times < 1) {
        console.warn("[CommonService.tryMultiple] Nepodařilo se provést akci:", action)
        return null
    }
    try {
        return action()
    } catch (error) {
        setTimeout(() => this.tryMultiple(action, timeout, times - 1), timeout)
    }
}

export let EMPTY_PROMISE = Promise.resolve({
        status: "OK"
})

export function okRes(data) {
    //console.log("Returning cached:")
    //console.log(data)
    return Promise.resolve({
        status: "OK",
        data: data,
    })
}

export function isNotEmpty(obj) {
    return !isEmpty(obj)
}

export function nullOrEmpty(val) {
    return val === undefined || !val || val === "" || val.length === 0
}

export function fallbackIfEmpty(val, fallback) {
    return isEmpty(val) ? fallback : val
}

export function firstItem(obj) {
    return obj[Object.keys(obj)[0]]
}

export function sortObject(obj, prop) {
    return Object.fromEntries(
        Object.entries(obj).sort((a, b) => a[prop] - b[prop])
    );
}

export function map(source, target) {
    Object.keys(source).forEach(key => target[key] = source[key])
}

export function clearSelection() {
    if (window.getSelection)
        window.getSelection().removeAllRanges()
    else if (document.selection)
        document.selection.empty()
}

export function smsHref(phoneIn, text) {
    const phone = phoneIn.startsWith("+420")
        ? phoneIn
        : `+420 ${phoneIn}`

    // ios
    if (this.isIos()) {
        this.copyToClipboard(text)
        return `sms:${phone}`
    }

    // other
    const encodedText = encodeURIComponent(text)

    return `sms:${phone}?body=${encodedText}`
}

export function sendWhatsapp(phoneIn, text) {
    if (isEmpty(phoneIn)) {
        console.warn("[CommonService.sendWhatsapp] Phone number is empty.")
        return
    }

    let phone = phoneIn.startsWith("+420")
        ? phoneIn
        : `+420${phoneIn}`

    phone = phone.replace("+", "")
                .replace(" ", "")
                .replace(/[\r\n]/gm, "%0a")

    // other
    const encodedText = encodeURIComponent(text)

    console.log(`wa.me/${phone}?text=${encodedText}`)

    window.open(`https://wa.me/${phone}?text=${encodedText}`, '_blank');
}

export function addOnClickOutside(divId, fn) {
    const listener = e => {
        // console.log("[CommonService.addOnClickOutside] click target", e.target)
        const div = document.getElementById(divId)

        if (!div) {
            console.warn(`[CommonService.addOnClickOutside] div with id ${divId} was not found.`)
            return
        }

        const clickInside = div.contains(e.target)

        if (!clickInside) {
            // console.log("[CommonService.addOnClickOutside] divId", divId)
            fn()
        }
    }

    window.addEventListener('click', listener);

    return listener
}

export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
    CommonElem.msg.info("Klíč zkopírován. 'CTRL + V' ho můžeš vložit.")
}

export function isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export let isMobile = window.innerWidth < 750
export let isDesktop = window.innerWidth >= 750

export function groupBy(arr, keyFunc) {
    return arr.reduce((result, item) => {
        const key = keyFunc(item);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, {});
}

export function distinct(array) {
    // from https://stackoverflow.com/questions/41550361/vanilla-javascript-unique-numbers-in-array-with-reduce-and-find
    return array.reduce((uniqueArray, number) => {
        if (uniqueArray.indexOf(number) === -1) {
            uniqueArray.push(number);
        }
        return uniqueArray;
    }, []);
}

export default {
    processError,
    isEmpty,
    redirect,
    headers,
    post,
    get,
    clone,
    copyProps,
    normalize,
    matchTexts,
    equalsNormalize,
    removeFromArray,
    waitFor,
    tryMultiple,
    EMPTY_PROMISE,
    okRes,
    isNotEmpty,
    nullOrEmpty,
    fallbackIfEmpty,
    firstItem,
    sortObject,
    map,
    clearSelection,
    smsHref,
    addOnClickOutside,
    copyToClipboard,
    isIos,
    isMobile,
    isDesktop,
    groupBy,
    distinct,
}